import * as React from "react"
import { Link, graphql } from 'gatsby'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const MeasurementCatsPage = ({data}) => {
  const entry = data.prismicMeasurementCatsPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag1}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
            <Link to="/contact/" className="mt-8 inline-block bg-orange px-3 py-1 text-2xl text-white font-poppins font-medium">Contact us</Link>
          </div>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="font-poppins text-center max-w-none prose prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.text.richText} />
          </div>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full md:w-3/12">
              <div>
                <GatsbyImage className="aspect-w-1 aspect-h-1 rounded-full w-full h-full object-cover block" image={entry.data.image1.gatsbyImageData} alt={entry.data.image1.alt ?? ''} />
              </div>
            </div>
            <div className="w-full md:w-9/12 pt-12 md:pt-0">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.data.heading1}</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text1.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Cat collar size</th>
              <th className="w-3/12 text-white p-3">Neck size (A)</th>
              <th className="w-3/12 text-white p-3">Collar width</th>
            </tr>
            {entry.data.collar_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.collar_size_col1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.collar_size_col1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Cat lead length</th>
              <th className="w-3/12 text-white p-3">Slim lead width</th>
              <th className="w-3/12 text-white p-3">Best use</th>
            </tr>
            {entry.data.lead_length.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.lead_length1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.lead_length1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Cat harness size</th>
              <th className="w-3/12 text-white p-3">Strap width</th>
              <th className="w-3/12 text-white p-3">Girth circumference (B)</th>
            </tr>
            {entry.data.harness_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.harness_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.harness_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full md:w-3/12">
              <div>
                <GatsbyImage className="aspect-w-1 aspect-h-1 rounded-full w-full h-full object-cover block" image={entry.data.image2.gatsbyImageData} alt={entry.data.image2.alt ?? ''} />
              </div>
            </div>
            <div className="w-full md:w-9/12 pt-12 md:pt-0">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.data.heading2}</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text2.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Feeding mat size</th>
              <th className="w-3/12 text-white p-3">Whole mat size</th>
              <th className="w-3/12 text-white p-3">Suits bowls</th>
            </tr>
            {entry.data.feeding_mat_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_mat_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_mat_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full block object-cover" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-8/12">
              &nbsp;
            </div>
            <div className="w-3/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                &nbsp;
              </div>
            </div>
            <div className="w-3/12 h-full bg-black">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-12 pb-12 md:pt-72 md:pb-24 text-right">
              <div className="font-poppins leading-tight text-xl md:text-2xl font-semibold">
                <h2 className="text-white mb-6"><em>“{entry.data.review_quote}”</em></h2>
                <p className="text-orange">- {entry.data.review_author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicMeasurementCatsPage {
    data {
      meta_description
      page_title
      tag1
      heading
      image {
        alt
        gatsbyImageData
      }
      text {
        richText
      }
      heading1
      text1 {
        richText
      }
      image1 {
        alt
        gatsbyImageData
      }
      collar_size {
        collar_size_col1
        collar_size_col2
        collar_size_col3
      }
      lead_length {
        lead_length1
        lead_length2
        lead_length3
      }
      harness_size {
        harness_size1
        harness_size2
        harness_size3
      }
      heading2
      text2 {
        richText
      }
      image2 {
        alt
        gatsbyImageData
      }
      feeding_mat_size {
        feeding_mat_size1
        feeding_mat_size2
        feeding_mat_size3
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_quote
      review_author
    }
  }
}
`

export default MeasurementCatsPage
